
.show-preview-button {
    position: fixed !important;
    top: 5rem;
    right: 2rem;
    background: #eee;
    border: 1px solid transparent !important;
    z-index: 100;
    transition: all ease-in-out 300ms !important;
}

.key-value {
    display: inline-block;
    position: relative;
    margin: .5rem;
    padding: .4rem; 
    width: 10%; 
    color: rgba(0, 0, 0, 0.6);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    text-align: center;
}
.key-value:before {
    content: 'Key';
    position: absolute;
    padding: 0 3px;
    top: -.7rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    background: #fff;
}
.modal {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100vw;
  max-height: 90vh;
  overflow: auto;
  background: #fff;
  border: 2px solid #000;
  box-shadow: 2px 4px 16px #333;
  padding: 2em;
}
